import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

import ClickAction from '@/components/ClickAction';
import Portal from '@/components/Portal/Portal';
import { IconLoader } from '@/components/images/IconLoader';

import { textClasses, backgroundClasses } from '../../../classes';

export const FloatingButton = ({ label, clickAction, icon, theme }) => {
  const contactButtonRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!contactButtonRef.current) return;
      contactButtonRef.current.classList.remove('translate-y-[150%]');
      contactButtonRef.current.classList.add('translate-y-0');
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!clickAction || !label) return null;

  return (
    <Portal id="contactButtonPortal">
      <ClickAction action={clickAction} section="floating button">
        <button
          ref={contactButtonRef}
          className={cx(
            'fixed bottom-4 right-4',
            textClasses[theme?.label?.color || 'blue-navy'],
            backgroundClasses[theme?.label?.background || 'white'],
            'drop-shadow-sm',
            'flex items-center gap-2',
            'rounded-full',
            'overflow-hidden',
            'transition-transform ease-out duration-300 translate-y-[150%]',
            'hover:underline focus:underline',
          )}
        >
          {label && (
            <span
              className={cx(
                'uppercase font-bold text-xs md:text-sm font-text block pl-3 md:pl-5 pr-1/2 md:pr-1 translate-y-[2px]',
                {
                  ['pr-3 md:pr-5']: !icon,
                },
              )}
            >
              {label}
            </span>
          )}

          {icon && (
            <span
              className={cx(
                'pr-3 py-2 pl-3',
                backgroundClasses[theme?.icon?.background || 'blue-navy'],
                textClasses[theme?.icon?.color || 'white'],
              )}
            >
              <IconLoader
                src={icon}
                color="currentColor"
                className="w-6 h-6 md:w-8 md:h-8 animate-pulse"
              />
            </span>
          )}
        </button>
      </ClickAction>
    </Portal>
  );
};

export default React.memo(FloatingButton);
