import { colorNames } from './config';

export const backgroundClasses = colorNames.reduce(
  (acc, color) => ({ ...acc, [color]: `bg-${color}` }),
  {},
);

export const textClasses = colorNames.reduce(
  (acc, color) => ({ ...acc, [color]: `text-${color}` }),
  {},
);

export const borderClasses = colorNames.reduce(
  (acc, color) => ({ ...acc, [color]: `border-${color}` }),
  {},
);

export const sizeClasses = {
  xs: 'text-xs',
  sm: 'text-xs md:text-sm',
  base: 'text-xs md:text-sm lg:text-base',
  lg: 'text-sm md:text-base lg:text-lg',
  xl: 'text-base md:text-lg lg:text-xl',
  '2xl': 'text-lg md:text-xl lg:text-2xl',
  '3xl': 'text-xl md:text-2xl lg:text-3xl',
  '4xl': 'text-2xl md:text-3xl lg:text-4xl',
  '5xl': 'text-3xl md:text-4xl lg:text-5xl',
  '6xl': 'text-4xl md:text-5xl lg:text-6xl',
  '7xl': 'text-5xl md:text-6xl lg:text-7xl',
};

export const weightClasses = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

export const fontClasses = {
  heading: 'font-heading',
  text: 'font-sans',
};

export const alignClasses = {
  left: 'text-left',
  center: 'text-center mx-auto',
  right: 'text-right ml-auto',
};

export const flexAlignClasses = {
  left: 'items-start',
  center: 'items-center',
  right: 'items-end',
};

export const flexJustifyClasses = {
  top: 'justify-start',
  middle: 'justify-center',
  bottom: 'justify-end',
};

export const proseSizeClasses = {
  xs: 'prose-sm',
  sm: 'prose-sm md:prose-base',
  base: 'prose-base md:prose-lg',
  lg: 'prose-lg md:prose-xl',
  xl: 'prose-xl md:prose-2xl',
};

export const leadingClasses = {
  none: 'leading-none',
  tighter: 'leading-tighter',
  tight: 'leading-tight',
  snug: 'leading-snug',
  normal: 'leading-normal',
  relaxed: 'leading-relaxed',
  loose: 'leading-loose',
};

export const aspectRatioClasses = {
  auto: 'aspect-auto',
  '1:1': 'aspect-[1/1]',
  '16:9': 'aspect-[16/9]',
  '3:2': 'aspect-[3/2]',
  '5:4': 'aspect-[5/4]',
  '9:16': 'aspect-[9/16]',
  '2:3': 'aspect-[2/3]',
  '4:5': 'aspect-[4/5]',
  '10:3': 'aspect-[10/3]',
};
