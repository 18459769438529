import cx from 'classnames';
import DOMPurify from 'dompurify';
import { useQuery } from 'react-query';

import { textClasses } from '../../classes';

export const IconLoader = ({ src, className, color }) => {
  const { isLoading, error, data } = useQuery(src, () =>
    fetch(src).then(async (res) => {
      const html = await res.text();
      let cleanHTML = DOMPurify.sanitize(html);
      cleanHTML = cleanUpAttributes(cleanHTML);
      if (src.indexOf('streamline') > -1)
        cleanHTML = replaceColorsWithCurrentColor(cleanHTML);
      return cleanHTML;
    }),
  );

  const legacy404Error = data?.indexOf('Sorry, page not found') > -1;

  if (error || legacy404Error)
    console.log(`Error loading icon ${src}: ${error?.message}`);

  if (isLoading || error || legacy404Error) return <div className={className} />;

  return (
    <div
      className={cx(className, textClasses[color])}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  );
};

/**
 * Remove width / height / style
 */

const cleanUpAttributes = (str) => {
  let parser = new DOMParser();
  let parsedResult = parser.parseFromString(str, 'image/svg+xml');

  // remove width and height so we can style with css
  parsedResult.documentElement.removeAttribute('width');
  parsedResult.documentElement.removeAttribute('height');

  // remove style attributes as they might overwrite styles on the site
  parsedResult.documentElement
    .querySelectorAll('style')
    .forEach((style) => style.parentNode.removeChild(style));

  return parsedResult.documentElement.outerHTML;
};

/**
 * Replace colours with currentColor so we can style them
 */

const replaceColorsWithCurrentColor = (str) => {
  return str
    .replace(/stroke="#000000"/g, 'stroke="currentColor"')
    .replace(/fill="#000000"/g, 'fill="currentColor"');
};
