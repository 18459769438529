const config = {};

config.colors = {
  white: '#ffffff',
  black: '#000000',
  gray: {
    spring: '#f7f6ee',
    china: '#e5e4dd',
    timber: '#dbd6d1',
    spanish: '#999999',
    dove: '#676767',
    granite: '#6a6657',
    deep: '#212321',
    // stone: '#6f6d5f',
    // sand: '#ded8d2',
    // limestone: '#e9e8e2',
    // sparrow: '#f8f7f1',
  },
  red: {
    peach: '#f0e4e4',
    candy: '#e9697e',
    rose: '#ca5263',
    maroon: '#b63a57',
    brick: '#b4284c',
    solid: '#833642',
    // eros: '#c95263',
    // brown: '#7c3e41',
  },
  yellow: {
    tangerine: '#ffcc00',
    amber: '#fdc000',
    curry: '#cb9e27',
    gold: '#b59e27',
    // tapenade: '#835923',
  },
  orange: {
    saffron: '#f5a136',
    tiger: '#d99132',
    princeton: '#f5841f',
    chinese: '#e86b3f',
    copper: '#c96d36',
    darkcopper: '#c06437',
  },
  brown: {
    ruddy: '#da9031',
    gold: '#b68400',
    saddle: '#836817',
    golden: '#8c5e23',
    bean: '#704121',
  },
  blue: {
    moon: '#dee5ef',
    denim: '#e1ebf2',
    steel: '#4380c2',
    azure: '#4a7eba',
    kashmir: '#4e638a',
    bayoux: '#4d6182',
    oxford: '#353e4c',
    vivid: '#00a9dd',
    cyanite: '#00b1e6',
    mermaid: '#0089bb',
    biscay: '#184a5f',
    regal: '#004d69',
    // river: '#0a2240',
    navy: '#072241',
    steel: '#394e67',
    // midnight: '#0a2141',
  },
  turquoise: {
    vivid: '#009fb5',
    toy: '#0089a0',
    lagoon: '#007589',
    nile: '#18474d',
    india: '#005d60',
  },
  green: {
    husk: '#a4ab49',
    sycamore: '#9a8e3d',
    gecko: '#9c8f3e',
    army: '#565122',
    oxley: '#709181',
    palmleaf: '#698a48',
    cucumber: '#7bac66',
    moss: '#678849',
    woodland: '#3d5028',
    ocean: '#4ca27f',
    sea: '#2f8065',
    myrtle: '#2e8066',
    tree: '#224c3b',
    gable: '#143d35',
    grass: '#7ab762',
    bush: '#4f8b34',
    land: '#3c5c29',
  },
  purple: {
    lavender: '#927eb9',
    orchid: '#9c6193',
    violet: '#7e5887',
    mortar: '#534057',
    cannon: '#8f4e6d',
    twilight: '#8f4d6c',
    blackberry: '#54343f',
    emperor: '#735887',
    // paisley: '#8b7eb4',
  },
  pastel: {
    orange: '#fbf2e7',
    paper: '#faf9f5',
    aqua: '#f4f8f7',
    pink: '#fcf6f7',
    sky: '#f2f9fb',
    vista: '#fef9f4',
    purple: '#f8f6f9',
    athens: '#f6f7f9',
    sand: '#e9e9e4',
    biscuit: '#f5e9e0',
    liberi: '#efe1da',
    blue: '#edf3f9',
  },
};

config.colorNames = Object.entries(config.colors).reduce(
  (acc, [name, colorOrItems]) => {
    if (typeof colorOrItems === 'object')
      return [
        ...acc,
        ...Object.entries(colorOrItems).reduce(
          (groupAcc, [childName]) => [...groupAcc, `${name}-${childName}`],
          [],
        ),
      ];
    return [...acc, name];
  },
  [],
);

config.colorDict = Object.entries(config.colors).reduce(
  (acc, [name, colorOrItems]) => {
    if (typeof colorOrItems === 'object')
      return {
        ...acc,
        ...Object.entries(colorOrItems).reduce(
          (groupAcc, [childName, color]) => ({
            ...groupAcc,
            [`${name}-${childName}`]: color,
          }),
          {},
        ),
      };
    return { ...acc, [name]: colorOrItems };
  },
  {},
);

module.exports = config;
